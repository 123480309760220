import React from "react";
import { Box, Typography } from "@mui/material";

const ScoreList = ({ scores = [] }) => {
  return scores && scores.length > 1 ? (
    scores
      .sort((a, b) => b.score - a.score)
      .map((s, i) => {
        return i < 15 && s.score !== 0 ? (
          <Box
            key={i}
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            width="250px"
            style={{
              backgroundColor: i % 2 === 0 ? "#4a4a4a" : "#303030",
              padding: "2px 8px 2px 8px",
            }}
          >
            <Box display="flex">
              <Box
                width="13px"
                display="flex"
                justifyContent="flex-end"
                alignItems="center"
                marginRight="8px"
              >
                <Typography style={{ color: "#6e6e6e", fontSize: "14px" }}>{i === 0 ? "👑" : i + 1}</Typography>
              </Box>
              <Typography style={{ color: "white", fontSize: i === 0 ? "24px" : "14px" }}>
                {s.name}
              </Typography>
            </Box>
            <Box>
              <Typography
                style={{
                  color: "white",
                  fontSize: i === 0 ? "24px" : "14px",
                }}
              >
                {s.score}
              </Typography>
            </Box>
          </Box>
        ) : null;
      })
  ) : (
    <Typography style={{ color: "#6e6e6e" }}>
      Be the first on the board today!
    </Typography>
  );
};

export default ScoreList;
