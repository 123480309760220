import React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import ScoreList from "./ScoreList";

const Leaderboard = ({ open, setOpen, scores, width, height, confettiOn }) => {
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      PaperProps={{
        style: {
          backgroundColor: "rgb(30, 30, 30)",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        },
      }}
      BackdropProps={{
        style: { backgroundColor: confettiOn ? "transparent" : "" },
      }}
    >
      <DialogTitle id="alert-dialog-title" style={{ color: "white" }}>
        LEADERBOARD
      </DialogTitle>
      <DialogContent>
        <ScoreList scores={scores} />
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => handleClose()}
          autoFocus
          variant="contained"
          color="success"
        >
          CLOSE
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default Leaderboard;
