import "../App.css";
import React from "react";
import Box from "@mui/material/Box";
import Row from "./Row";

const Board = ({
  prevWords,
  currentWord,
  letterValues,
  clearRow,
  currentTotal,
}) => {
  const rows = [];
  const activeRowIndex = prevWords.length;
  for (let i = 0; i < 5; i++) {
    let wordArr;
    if (prevWords[i]) {
      wordArr = prevWords[i];
    } else if (currentWord && i === activeRowIndex) {
      wordArr = currentWord;
    }
    rows.push(
      <Row
        index={i}
        clearRow={clearRow}
        letters={wordArr}
        submitted={i < activeRowIndex}
        letterValues={letterValues}
        currentRow={prevWords.length === i}
      />
    );
  }
  return (
    <Box display="flex" flexDirection="column" justifyContent="center">
      <Box>{rows}</Box>
      <Row totalBox currentTotal={currentTotal} />
    </Box>
  );
};

export default Board;
