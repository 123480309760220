import React, { useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { Box } from "@mui/system";
import { Typography, TextField } from "@mui/material";
import { withStyles } from "@mui/styles";
import { getLocalStorage } from "./helpers";
import { addScoreToLeaderboard } from "./firebase/helpers";
import { blockedWords } from "./blockedWords";

const styles = (theme) => ({
  disabledButton: {
    backgroundColor: "gray !important",
    color: "white",
  },
});

const AlertDialog = ({
  alertDialogOpen,
  setAlertDialogOpen,
  message,
  setAlertMessage,
  alertTitle,
  setAlertTitle,
  alertScore,
  setAlertScore,
  setPrevWords,
  beatPersonalBest,
  setBeatPersonalBest,
  copyToClipboard,
  confettiOn,
  setConfettiOn,
  classes,
  setLeaderboardOpen,
  setGoldConfettiOn,
  resetBoard
}) => {
  const [name, setName] = useState("");
  const [error, setError] = useState(false);
  const [scoreSavedToLeaderboard, setScoreSavedToLeaderboard] = useState(false);

  const handleClose = (reset) => {
    setAlertDialogOpen(false);
    setConfettiOn(false);
    setGoldConfettiOn(false);
    if (reset) {
      resetBoard();
    }
    setTimeout(() => {
      setScoreSavedToLeaderboard(false);
      setAlertMessage("");
      setAlertTitle("");
      setAlertScore("");
      setBeatPersonalBest("");
    }, 1000);
  };

  const handleAddScoreToLeaderboard = async () => {
    for (let i = 0; i < blockedWords.length; i++) {
      const lowercaseName = name.toLowerCase();
      if (lowercaseName.includes(blockedWords[i])) {
        setError(true);
        return;
      }
    }
    setScoreSavedToLeaderboard(true);
    await addScoreToLeaderboard(alertScore, name);
    setLeaderboardOpen(true);
  };

  return (
    <Dialog
      open={alertDialogOpen}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      PaperProps={{
        style: {
          backgroundColor: "rgb(30, 30, 30)",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        },
      }}
      BackdropProps={{
        style: { backgroundColor: confettiOn ? "transparent" : "" },
      }}
    >
      {alertTitle && (
        <DialogTitle id="alert-dialog-title" style={{ color: "white" }}>
          {alertTitle}
        </DialogTitle>
      )}
      <DialogContent>
        <DialogContentText
          id="alert-dialog-description"
          style={{ color: "#b0b0b0", textAlign: "center" }}
        >
          {message}
        </DialogContentText>
        {alertScore && (
          <>
            <Box>
              <Typography
                variant="h2"
                style={{ color: "white", textAlign: "center" }}
              >
                {alertScore}
              </Typography>
            </Box>
            <Box textAlign="center">
              {beatPersonalBest ? (
                <Box>
                  <Typography variant="h6" style={{ color: "#a6a6a6" }}>
                    New personal best!
                  </Typography>
                </Box>
              ) : (
                <Box>
                  <Typography variant="h6" style={{ color: "#a6a6a6" }}>
                    {`Personal best: ${getLocalStorage("personalBest") || 0}`}
                  </Typography>
                </Box>
              )}
            </Box>
          </>
        )}
        {alertScore && (
          <Box
            marginTop="16px"
            width="260px"
            display="flex"
            flexDirection="column"
          >
            <Box marginBottom="8px">
              <Typography style={{ color: "gray", fontSize: "12px" }}>
                Your name:
              </Typography>
              <TextField
                error={error}
                sx={{ input: { color: "white" } }}
                inputProps={{ maxLength: 20 }}
                disabled={scoreSavedToLeaderboard}
                margin="none"
                size="small"
                hiddenLabel
                variant="filled"
                style={{ backgroundColor: "#333333", color: "white" }}
                fullWidth
                onChange={(e) => {
                  setName(e.target.value);
                  if (error) setError(false);
                }}
              />
            </Box>
            <Button
              classes={{ disabled: classes.disabledButton }}
              disabled={!name || scoreSavedToLeaderboard}
              onClick={() => handleAddScoreToLeaderboard()}
              autoFocus
              variant="contained"
              color="success"
              fullWidth
            >
              ADD SCORE TO LEADERBOARD
            </Button>
          </Box>
        )}
      </DialogContent>
      <DialogActions>
        {alertScore && (
          <Button
            onClick={() => copyToClipboard()}
            autoFocus
            variant="contained"
            color="primary"
          >
            SHARE
          </Button>
        )}
        {alertScore && (
          <Button
            onClick={() => handleClose(true)}
            autoFocus
            variant="contained"
            color="success"
          >
            PLAY AGAIN
          </Button>
        )}
        <Button onClick={() => handleClose()} autoFocus variant="contained">
          CLOSE
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default withStyles(styles)(AlertDialog);
