import React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { Box } from "@mui/system";
import { getLocalStorage, setLocalStorage } from "./helpers";
import { Typography } from "@mui/material";

const WhatsNewDialog = ({ whatsNewDialogOpen, setWhatsNewDialogOpen }) => {
  // const [whatsNew, setWhatsNew] = useState([]);
  const handleClose = () => {
    setWhatsNewDialogOpen(false);
    setLocalStorage("whatsNewSeen", true);
  };

  // useEffect(() => {
  //   setWhatsNew(JSON.parse(getLocalStorage("whatsNew")));
  // }, []);

  const whatsNew = JSON.parse(getLocalStorage('whatsNew'));

  return (
    <Dialog
      open={whatsNewDialogOpen}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      PaperProps={{
        style: {
          backgroundColor: "rgb(30, 30, 30)",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        },
      }}
    >
      <DialogTitle id="alert-dialog-title" style={{ color: "white" }}>
        WHAT'S NEW
      </DialogTitle>

      <DialogContent>
        <Box display="flex" justifyContent="flex-start">
          <DialogContentText
            id="alert-dialog-description"
            style={{ color: "#b0b0b0" }}
          >
            {whatsNew && whatsNew.map((item, i) => {
              return (
                <Box display="flex">
                  <Typography>{i + 1}.&nbsp;</Typography>
                  <Typography>{item}</Typography>
                </Box>
              );
            })}
          </DialogContentText>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => handleClose()}
          autoFocus
          variant="contained"
          color="success"
        >
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default WhatsNewDialog;
