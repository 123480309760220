import { logEvent } from "firebase/analytics";
import { analytics } from "./firebase";

const moment = require("moment-timezone");

export const setLocalStorage = (key, value) => {
  localStorage.setItem(key, value);
};

export const getLocalStorage = (key) => {
  return localStorage.getItem(key);
};

export const removeLocalStorage = (key) => {
  localStorage.getItem(key);
};

export const isFirstVisitOfDay = () => {
  const lastVisit = new Date(getLocalStorage("lastVisit"));

  if (!lastVisit) {
    return true;
  }

  const currentDateTime = new Date(moment().tz("America/Los_Angeles").format());

  const lastVisitDate = lastVisit.getDate();
  const lastVisitMonth = lastVisit.getMonth();
  const lastVisitYear = lastVisit.getYear();
  const currentDateTimeDate = currentDateTime.getDate();
  const currentDateTimeMonth = currentDateTime.getMonth();
  const currentDateTimeYear = currentDateTime.getYear();

  if (
    currentDateTimeDate > lastVisitDate ||
    currentDateTimeMonth > lastVisitMonth ||
    currentDateTimeYear > lastVisitYear
  ) {
    return true;
  }

  return false;
};

const fallBackCopyTextToClipboard = (string, cb) => {
  let textarea;
  let result;

  try {
    textarea = document.createElement("textarea");
    textarea.setAttribute("readonly", true);
    textarea.setAttribute("contenteditable", true);
    textarea.style.position = "fixed"; // prevent scroll from jumping to the bottom when focus is set.
    textarea.value = string;

    document.body.appendChild(textarea);

    textarea.focus();
    textarea.select();

    const range = document.createRange();
    range.selectNodeContents(textarea);

    const sel = window.getSelection();
    sel.removeAllRanges();
    sel.addRange(range);

    textarea.setSelectionRange(0, textarea.value.length);
    result = document.execCommand("copy");
    console.log(result);
    cb && cb();
  } catch (err) {
    console.error(err);
    result = null;
  } finally {
    document.body.removeChild(textarea);
  }

  // manual copy fallback using prompt
  if (!result) {
    const isMac = navigator.platform.toUpperCase().indexOf("MAC") >= 0;
    const copyHotkey = isMac ? "⌘C" : "CTRL+C";
    result = prompt(`Press ${copyHotkey}`, string); // eslint-disable-line no-alert
    if (!result) {
      return false;
    }
  }
  return true;
};

export const copyTextToClipboard = async (text, cb) => {
  logEvent(analytics, "share");
  fallBackCopyTextToClipboard(text, cb);
  await navigator.clipboard.writeText(text).then(
    function () {
      console.log("Async: Copying to clipboard was successful!");
      cb && cb();
    },
    function (err) {
      console.error("Async: Could not copy text: ", err);
    }
  );
};

export const shouldRefresh = () => {
  const lastVisit = new Date(getLocalStorage("lastVisit"));

  if (!lastVisit) {
    // This means they've never been to the site until this point in time
    // so the app should be fresh and up to date by default
    return false;
  }

  const currentDateTime = new Date(moment().tz("America/Los_Angeles").format());

  const lastVisitDate = lastVisit.getDate();
  const lastVisitMonth = lastVisit.getMonth();
  const lastVisitYear = lastVisit.getYear();
  const currentDateTimeDate = currentDateTime.getDate();
  const currentDateTimeMonth = currentDateTime.getMonth();
  const currentDateTimeYear = currentDateTime.getYear();

  if (
    currentDateTimeDate > lastVisitDate ||
    currentDateTimeMonth > lastVisitMonth ||
    currentDateTimeYear > lastVisitYear
  ) {
    return true;
  }
  return false;
};