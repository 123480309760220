import { doc, getDoc, updateDoc, arrayUnion } from "firebase/firestore";
import { db } from "../firebase";

// export const testFunc = () => {
//   const alpha = Array.from(Array(26)).map((e, i) => i + 65);
//   const sortedAlphabet = alpha.map((x) => String.fromCharCode(x));

//   const shuffle = (array) => {
//     let currentIndex = array.length,
//       randomIndex;
//     while (currentIndex !== 0) {
//       randomIndex = Math.floor(Math.random() * currentIndex);
//       currentIndex--;
//       [array[currentIndex], array[randomIndex]] = [
//         array[randomIndex],
//         array[currentIndex],
//       ];
//     }
//     return array;
//   };

//   // Always have empty string at [0] so lowest letter point is 1
//   const randomizedAlphabet = ["", ...shuffle(sortedAlphabet)];

//   console.log(randomizedAlphabet);
// };

export const getInitialData = async () => {
  const settingsRef = doc(db, "game", "settings");
  const settingsSnap = await getDoc(settingsRef);
  const statsRef = doc(db, "game", "stats");
  const statsSnap = await getDoc(statsRef);

  const data = {};

  if (settingsSnap.exists()) {
    const settings = settingsSnap.data();
    data.letterValues = settings.letterValues;
  }
  if (statsSnap.exists()) {
    const stats = statsSnap.data();
    data.wfVersion = stats.wfVersion;
  }

  return data;
};

export const setHighScore = async (score, words) => {
  const statsRef = doc(db, "game", "stats");

  await updateDoc(statsRef, {
    highestScore: score,
    highestScoreWords: JSON.stringify(words),
  });
};

export const getHighScore = async () => {
  const statsRef = doc(db, "game", "stats");
  const statsSnap = await getDoc(statsRef);

  const data = {};

  if (statsSnap.exists()) {
    const stats = statsSnap.data();
    data.highestScore = stats.highestScore;
    data.highestScoreWords = stats.highestScoreWords;
  }

  return data;
};

export const getAllScores = async () => {
  const scoresRef = doc(db, "game", "scores");
  const scoresSnap = await getDoc(scoresRef);

  let scores = null;

  if (scoresSnap.exists()) {
    scores = scoresSnap.data().scores;
  }

  return scores;
};

export const addScoreToLeaderboard = async (score, name) => {
  const scoresRef = doc(db, "game", "scores");

  await updateDoc(scoresRef, {
    scores: arrayUnion({ score, name, dateTime: new Date() }),
  });
};
