import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { Box } from "@mui/system";

const InitialDialog = ({
  initialDialogOpen,
  setInitialDialogOpen,
  goldenVowel,
}) => {
  const handleClose = () => {
    setInitialDialogOpen(false);
  };

  return (
    <Dialog
      open={initialDialogOpen}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      PaperProps={{
        style: {
          backgroundColor: "rgb(30, 30, 30)",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        },
      }}
    >
      <DialogTitle id="alert-dialog-title" style={{ color: "white" }}>
        HOW TO PLAY
      </DialogTitle>

      <DialogContent>
        <Box display="flex" justifyContent="flex-start">
          <DialogContentText
            id="alert-dialog-description"
            style={{ color: "#b0b0b0" }}
          >
            Try to get the highest total of points possible in 5 words!
            <br />
            <br />
            Each letter on the board is assigned a point value and may only be
            used in the same position once... all except for the{" "}
            <strong>
              <span style={{ color: "white" }}>Golden Vowel</span>
            </strong>
            , which can be used anywhere, any time! If you need to remove a
            word, just double click/tap on the row.
            <br />
            <br />
            Point values and the Golden Vowel are randomized every day at
            midnight (PT), but you may play as many times you like per day!
          </DialogContentText>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => handleClose()}
          autoFocus
          variant="contained"
          color="success"
        >
          LET'S GO
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default InitialDialog;
