/* eslint-disable react-hooks/exhaustive-deps */
import axios from "axios";
import useWindowSize from "react-use/lib/useWindowSize";
import Confetti from "react-confetti";
import LeaderboardIcon from "@mui/icons-material/Leaderboard";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import RotateLeftOutlinedIcon from "@mui/icons-material/RotateLeftOutlined";
import "./App.css";
import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Keyboard from "./Keyboard";
import Board from "./Board";
import {
  // testFunc,
  setHighScore,
  getAllScores,
} from "./firebase/helpers";
import {
  getLocalStorage,
  setLocalStorage,
  isFirstVisitOfDay,
  copyTextToClipboard,
  shouldRefresh,
} from "./helpers";
import { allowedWords } from "./allowedWords";
import AlertDialog from "./AlertDialog";
import InitialDialog from "./InitialDialog";
import logo from "./assets/images/werdflexlogo.png";
import { Typography } from "@mui/material";
import CopiedDialog from "./CopiedDialog";
import { db, firebase, analytics } from "./firebase";
import { logEvent } from "firebase/analytics";
import { doc, onSnapshot } from "firebase/firestore";
import Leaderboard from "./Leaderboard";
import ResetDialog from "./ResetDialog";
import WhatsNewDialog from "./WhatsNewDialog";
// import AdComponent from "./AdComponent";
const moment = require("moment-timezone");

const App = () => {
  const { width, height } = useWindowSize();
  const [letterValues, setLetterValues] = useState([]);
  const [wfVersion, setWfVersion] = useState(0);
  const [prevWords, setPrevWords] = useState([]);
  const [currentWord, setCurrentWord] = useState([]);
  const [boardDisabled, setBoardDisabled] = useState(false);
  const [alertDialogOpen, setAlertDialogOpen] = useState(false);
  const [initialDialogOpen, setInitialDialogOpen] = useState(false);
  const [copiedDialogOpen, setCopiedDialogOpen] = useState(false);
  const [leaderboardOpen, setLeaderboardOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [alertTitle, setAlertTitle] = useState("");
  const [alertScore, setAlertScore] = useState(null);
  const [beatPersonalBest, setBeatPersonalBest] = useState(false);
  const [score, setScore] = useState(0);
  const [highestScore, setHighestScore] = useState(0);
  const [currentTotal, setCurrentTotal] = useState(0);
  const [confettiOn, setConfettiOn] = useState(false);
  const [goldConfettiOn, setGoldConfettiOn] = useState(false);
  const [leaderboardScores, setLeaderboardScores] = useState([]);
  const [goldenVowel, setGoldenVowel] = useState("");
  const [gameFinished, setGameFinished] = useState(false);
  const [resetDialogOpen, setResetDialogOpen] = useState(false);
  const [whatsNewDialogOpen, setWhatsNewDialogOpen] = useState(false);

  useEffect(() => {
    const unsub = onSnapshot(doc(db, "game", "stats"), (doc) => {
      const wfVersion = doc.data().wfVersion;
      // const highScore = doc.data().highestScore;
      // if (highScore !== null && highScore !== undefined) {
      //   setHighestScore(highScore);
      // }
      if (wfVersion) {
        setWfVersion(wfVersion);
        setLocalStorage("wfVersion", wfVersion);
      }
    });
    return () => {
      unsub();
    };
  }, [firebase]);

  useEffect(() => {
    const unsub = onSnapshot(doc(db, "game", "settings"), (doc) => {
      const newLetterValues = doc.data().letterValues;
      const goldenVowel = doc.data().goldenVowel;
      const whatsNew = doc.data().whatsNew;

      if (letterValues) {
        setLetterValues(newLetterValues);
        setLocalStorage("letterValues", newLetterValues);

        // For people who dont refresh at least once per day for app updates,
        // reload the page for them when the letter values renew at midnight
        if (shouldRefresh()) {
          logEvent(analytics, "forced_reload");
          window.location.reload();
        }
        if (isFirstVisitOfDay()) {
          if (!getLocalStorage("personalBest")) {
            setLocalStorage("personalBest", 0);
          }
          resetBoard();
        }
      }

      if (goldenVowel) {
        setGoldenVowel(goldenVowel);
        setLocalStorage("goldenVowel", goldenVowel);
      }

      if (
        whatsNew &&
        JSON.stringify(whatsNew) !== getLocalStorage("whatsNew")
      ) {
        console.log('hi')
        setLocalStorage("whatsNewSeen", false);
        setLocalStorage("whatsNew", JSON.stringify(whatsNew));
        setWhatsNewDialogOpen(true);
      }
    });
    return () => {
      unsub();
    };
  }, [firebase]);

  useEffect(() => {
    const unsub = onSnapshot(doc(db, "game", "scores"), (doc) => {
      const scores = doc.data().scores;
      if (scores) {
        setLeaderboardScores(scores);
        let highestScore = 0;
        for (let i = 0; i < scores.length; i++) {
          if (scores[i].score > highestScore) {
            highestScore = scores[i].score;
          }
        }
        setHighestScore(highestScore);
      }
    });
    return () => {
      unsub();
    };
  }, [firebase]);

  useEffect(() => {
    if (leaderboardOpen) {
      const fetchScores = async () => {
        const scores = await getAllScores();
        setLeaderboardScores(scores);
      };
      fetchScores();
    }
  }, [leaderboardOpen]);

  useEffect(() => {
    if (copiedDialogOpen) {
      setTimeout(() => setCopiedDialogOpen(false), 4000);
    }
  }, [copiedDialogOpen]);

  useEffect(() => {
    const hasPlayedBefore = getLocalStorage("hasPlayedBefore");
    if (!hasPlayedBefore) {
      setInitialDialogOpen(true);
      setLocalStorage("hasPlayedBefore", true);
    }
  }, []);

  useEffect(() => {
    const stringifiedPrevWords = getLocalStorage("prevWords");
    if (stringifiedPrevWords) {
      const prevWords = JSON.parse(stringifiedPrevWords);
      setPrevWords(prevWords);
    }
  }, []);

  useEffect(() => {
    logEvent(analytics, "level_start");
    const dateTimeLA = moment().tz("America/Los_Angeles").format();
    setLocalStorage("lastVisit", dateTimeLA);
  }, []);

  const checkFinalScore = async (score) => {
    logEvent(analytics, "level_end");
    setLocalStorage("prevWords", JSON.stringify([]));
    setGameFinished(true);
    setScore(score);
    const personalBest = getLocalStorage("personalBest");

    if (score > highestScore) {
      setGoldConfettiOn(true);
      setBeatPersonalBest(true);
      setAlertTitle(
        `You beat today's previous highest score of ${highestScore || 0}!`
      );
      setAlertMessage(`Your score:`);
      setAlertScore(score);
      setAlertDialogOpen(true);
      setHighScore(score, prevWords);
      setLocalStorage("personalBest", score);
    } else if (score > personalBest) {
      setConfettiOn(true);
      setBeatPersonalBest(true);
      setAlertTitle(`Nice!`);
      setAlertMessage(`Your score was:`);
      setAlertScore(score);
      setAlertDialogOpen(true);
      setLocalStorage("personalBest", score);
    } else {
      setAlertTitle(`Nice!`);
      setAlertMessage(`Your score was:`);
      setAlertScore(score);
      setAlertDialogOpen(true);
    }
  };

  useEffect(() => {
    if (letterValues && letterValues.length) {
      let sum = 0;
      prevWords.forEach((w) => {
        w.forEach((l) => {
          sum += letterValues.indexOf(l.toUpperCase());
        });
      });
      prevWords.length !== 5 &&
        currentWord &&
        currentWord.forEach((l) => {
          sum += letterValues.indexOf(l.toUpperCase());
        });

      setCurrentTotal(sum);

      if (prevWords.length === 5) {
        setBoardDisabled(true);
        checkFinalScore(sum);
        // TODO: Submit the score and show modal
      } else {
        if (boardDisabled) setBoardDisabled(false);
      }
    }
  }, [
    JSON.stringify(prevWords),
    letterValues && JSON.stringify(letterValues),
    currentWord && JSON.stringify(currentWord),
  ]);

  const isValidWord = async (word) => {
    if (word && Array.isArray(word)) {
      word = word.join("");
    }

    if (allowedWords.includes(word.toLowerCase())) {
      return true;
    }

    try {
      const res = await axios.get(
        `https://api.dictionaryapi.dev/api/v2/entries/en/${word}`
      );
      if (res && res.status === 200) {
        return true;
      }
    } catch (err) {
      return false;
    }
  };

  const isWordAlreadyUsed = (word) => {
    if (word && Array.isArray(word)) {
      word = word.join("");
    }

    const prevWordsJoined = prevWords.map((w) => w.join(""));

    return prevWordsJoined.includes(word);
  };

  const copyToClipboard = () => {
    copyTextToClipboard(
      `I got ${score} points in Wordflex #${wfVersion}! Try it out: werdflex.com`,
      () => {
        setCopiedDialogOpen(true);
      }
    );
  };

  const clearRow = (index) => {
    if (!gameFinished) {
      const newPrevWords = [...prevWords];
      newPrevWords.splice(index, 1);
      setPrevWords(newPrevWords);
      setLocalStorage("prevWords", JSON.stringify(newPrevWords));
    }
  };

  const handleResetBoard = () => {
    if (prevWords.length) {
      setResetDialogOpen(true);
    }
  };

  const resetBoard = () => {
    setPrevWords([]);
    setLocalStorage("prevWords", JSON.stringify([]));
    setGameFinished(false);
  };

  const handleSubmit = async (word) => {
    const wordIsValid = await isValidWord(word);
    const wordAlreadyUsed = isWordAlreadyUsed(word);
    if (wordAlreadyUsed) {
      setAlertMessage("You already used that word!");
      setAlertDialogOpen(true);
      setCurrentWord([]);
    } else if (wordIsValid) {
      setPrevWords([...prevWords, word]);
      setLocalStorage("prevWords", JSON.stringify([...prevWords, word]));
      logEvent(analytics, `word_accepted: ${word.join("")}`);
      setCurrentWord([]);
    } else {
      setAlertMessage("Nice word, but it isn't in our dictionary!");
      setAlertDialogOpen(true);
      logEvent(analytics, `word_rejected: ${word.join("")}`);
      setCurrentWord([]);
    }
  };

  return (
    <Box className="App">
      <Box className="App-container">
        {confettiOn && <Confetti width={width} height={height} />}
        {goldConfettiOn && (
          <Confetti
            width={width}
            height={height}
            colors={["#e0c169", "#e8e1cf", "#e8aa25"]}
          />
        )}
        {/* <AdComponent /> */}
        <Box
          display="flex"
          justifyContent="space-between"
          maxWidth="500px"
          width="100vw"
          alignItems="flex-end"
        >
          <Box display="flex" flexGrow={1} justifyContent="space-evenly">
            <Box display="flex" onClick={() => setInitialDialogOpen(true)}>
              <InfoOutlinedIcon style={{ fill: "white", fontSize: "30px" }} />
            </Box>
            <Box display="flex" onClick={handleResetBoard}>
              <RotateLeftOutlinedIcon
                style={{ fill: "white", fontSize: "30px" }}
              />
            </Box>
          </Box>
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            width="calc(100vw / 2)"
            maxWidth="200px"
            marginBottom="4px"
          >
            <img
              src={logo}
              alt="logo"
              style={{ width: "100%", height: "100%", objectFit: "contain" }}
            />
          </Box>
          <Box display="flex" flexGrow={1} justifyContent="space-evenly">
            <Box display="flex">
              <RotateLeftOutlinedIcon
                style={{ fill: "none", fontSize: "30px" }}
              />
            </Box>
            <Box onClick={() => setLeaderboardOpen(true)}>
              <LeaderboardIcon style={{ fill: "white", fontSize: "30px" }} />
            </Box>
          </Box>
        </Box>
        <Box />
        <Box display="flex" flexDirection="column" alignItems="center">
          <Board
            currentWord={currentWord}
            prevWords={prevWords}
            letterValues={letterValues}
            currentRow={prevWords.length}
            clearRow={clearRow}
            currentTotal={currentTotal}
          />
        </Box>
        <Box display="flex" flexDirection="column" alignItems="center">
          <Keyboard
            boardDisabled={boardDisabled}
            letterValues={letterValues}
            // testFunc={testFunc}
            handleSubmit={handleSubmit}
            currentWord={currentWord}
            setCurrentWord={setCurrentWord}
            prevWords={prevWords}
            currentIndex={currentWord.length}
            alertDialogOpen={alertDialogOpen}
            goldenVowel={goldenVowel}
          />
          <Typography style={{ color: "#4f4f4f", fontSize: "12px" }}>
            © 2022 CKR
          </Typography>
        </Box>
        <AlertDialog
          alertDialogOpen={alertDialogOpen}
          setAlertDialogOpen={setAlertDialogOpen}
          message={alertMessage}
          setAlertMessage={setAlertMessage}
          alertTitle={alertTitle}
          setAlertTitle={setAlertTitle}
          alertScore={alertScore}
          setAlertScore={setAlertScore}
          setPrevWords={setPrevWords}
          beatPersonalBest={beatPersonalBest}
          setBeatPersonalBest={setBeatPersonalBest}
          copyToClipboard={copyToClipboard}
          confettiOn={confettiOn || goldConfettiOn}
          setConfettiOn={setConfettiOn}
          setGoldConfettiOn={setGoldConfettiOn}
          setLeaderboardOpen={setLeaderboardOpen}
          setGameFinished={setGameFinished}
          resetBoard={resetBoard}
        />
        <InitialDialog
          initialDialogOpen={initialDialogOpen}
          setInitialDialogOpen={setInitialDialogOpen}
          goldenVowel={goldenVowel}
        />
        <CopiedDialog copiedDialogOpen={copiedDialogOpen} />
        <Leaderboard
          open={leaderboardOpen}
          setOpen={setLeaderboardOpen}
          scores={leaderboardScores}
          confettiOn={confettiOn || goldConfettiOn}
        />
        <ResetDialog
          resetDialogOpen={resetDialogOpen}
          setResetDialogOpen={setResetDialogOpen}
          resetBoard={resetBoard}
        />
        <WhatsNewDialog
          whatsNewDialogOpen={whatsNewDialogOpen}
          setWhatsNewDialogOpen={setWhatsNewDialogOpen}
        />
      </Box>
    </Box>
  );
};

export default App;
