import "../App.css";
import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { ButtonBase } from "@mui/material";

const RowOne = ({
  onClick,
  rowFull,
  testFunc,
  letterValues,
  boardDisabled,
  prevWords,
  currentIndex,
  goldenVowel,
}) => {
  const letters = ["q", "w", "e", "r", "t", "y", "u", "i", "o", "p"];

  const letterDisabled = (letter, currentIndex) => {
    for (let i = 0; i < prevWords.length; i++) {
      const word = prevWords[i];
      if (letter === word[currentIndex] && letter !== goldenVowel) {
        return true;
      }
    }
    return false;
  };

  return (
    <Box display="flex" justifyContent="center">
      {/* <Button onClick={() => testFunc()}>test</Button> */}
      {letters.map((l) => {
        const shouldBeDisabled =
          rowFull || boardDisabled || letterDisabled(l, currentIndex);
        const keyStyles = (disabled) => {
          if (disabled) return "letter-key-dark";
          if (!disabled && l !== goldenVowel) return "letter-key";
          if (!disabled && l === goldenVowel) return "letter-key-gold";
        };
        return (
          <Box
            className={keyStyles(shouldBeDisabled)}
          >
            <ButtonBase
              onClick={() =>
                !boardDisabled && !letterDisabled(l, currentIndex) && onClick(l)
              }
              centerRipple
              style={{ width: "100%", height: "100%" }}
            >
              {letterValues && (
                <Box>
                  <Typography
                    fontSize="22px"
                    style={{ userSelect: "none" }}
                  >
                    {l.toUpperCase()}
                  </Typography>
                  <Typography
                    fontSize="12px"
                    style={{ userSelect: "none", color: "white" }}
                  >
                    {letterValues.indexOf(l.toUpperCase())}
                  </Typography>
                </Box>
              )}
            </ButtonBase>
          </Box>
        );
      })}
    </Box>
  );
};

const RowTwo = ({
  onClick,
  rowFull,
  letterValues,
  boardDisabled,
  prevWords,
  currentIndex,
  goldenVowel,
}) => {
  const letters = ["a", "s", "d", "f", "g", "h", "j", "k", "l"];

  const letterDisabled = (letter, currentIndex) => {
    for (let i = 0; i < prevWords.length; i++) {
      const word = prevWords[i];
      if (letter === word[currentIndex] && letter !== goldenVowel) {
        return true;
      }
    }
    return false;
  };

  return (
    <Box display="flex" justifyContent="center">
      {letters.map((l) => {
        const shouldBeDisabled =
          rowFull || boardDisabled || letterDisabled(l, currentIndex);
        const keyStyles = (disabled) => {
          if (disabled) return "letter-key-dark";
          if (!disabled && l !== goldenVowel) return "letter-key";
          if (!disabled && l === goldenVowel) return "letter-key-gold";
        };
        return (
          <Box className={keyStyles(shouldBeDisabled)}>
            <ButtonBase
              onClick={() =>
                !boardDisabled && !letterDisabled(l, currentIndex) && onClick(l)
              }
              centerRipple
              style={{ width: "100%", height: "100%" }}
            >
              {letterValues && (
                <Box>
                  <Typography
                    fontSize="22px"
                    style={{ userSelect: "none" }}
                  >
                    {l.toUpperCase()}
                  </Typography>
                  <Typography
                    fontSize="12px"
                    style={{ userSelect: "none", color: 'white' }}
                  >
                    {letterValues.indexOf(l.toUpperCase())}
                  </Typography>
                </Box>
              )}
            </ButtonBase>
          </Box>
        );
      })}
    </Box>
  );
};

const RowThree = ({
  onClick,
  rowFull,
  rowEmpty,
  letterValues,
  boardDisabled,
  prevWords,
  currentIndex,
  alertDialogOpen,
}) => {
  const letters = ["z", "x", "c", "v", "b", "n", "m"];

  const letterDisabled = (letter, currentIndex) => {
    for (let i = 0; i < prevWords.length; i++) {
      const word = prevWords[i];
      if (letter === word[currentIndex]) {
        return true;
      }
    }
    return false;
  };

  return (
    <Box display="flex" justifyContent="center">
      <Box
        display="flex"
        width="calc(100% / 8)"
        height="55px"
        borderRadius="4px"
        bgcolor={
          rowFull && !boardDisabled && !alertDialogOpen
            ? "rgb(129, 131, 132)"
            : "rgb(58, 58, 60)"
        }
        margin="3px"
        alignItems="center"
        justifyContent="center"
      >
        <ButtonBase
          onClick={() => {
            if (rowFull && !boardDisabled && !alertDialogOpen) onClick("ENTER");
          }}
          centerRipple
          style={{ width: "100%", height: "100%" }}
        >
          <Box>
            <Typography
              fontSize="10px"
              color="white"
              style={{ userSelect: "none" }}
            >
              ENTER
            </Typography>
          </Box>
        </ButtonBase>
      </Box>
      {letters.map((l) => {
        return (
          <Box
            className={
              rowFull || boardDisabled || letterDisabled(l, currentIndex)
                ? "letter-key-dark"
                : "letter-key"
            }
          >
            <ButtonBase
              onClick={() =>
                !boardDisabled && !letterDisabled(l, currentIndex) && onClick(l)
              }
              centerRipple
              style={{ width: "100%", height: "100%" }}
            >
              {letterValues && (
                <Box>
                  <Typography
                    fontSize="22px"
                    style={{ userSelect: "none" }}
                  >
                    {l.toUpperCase()}
                  </Typography>
                  <Typography
                    fontSize="12px"
                    style={{ userSelect: "none" }}
                  >
                    {letterValues.indexOf(l.toUpperCase())}
                  </Typography>
                </Box>
              )}
            </ButtonBase>
          </Box>
        );
      })}
      <Box
        display="flex"
        width="calc(100% / 8)"
        height="55px"
        borderRadius="4px"
        bgcolor={rowEmpty ? "rgb(58, 58, 60)" : "rgb(129, 131, 132)"}
        margin="3px"
        alignItems="center"
        justifyContent="center"
      >
        <ButtonBase
          onClick={() => {
            if (!rowEmpty && !boardDisabled) onClick("DELETE");
          }}
          centerRipple
          style={{ width: "100%", height: "100%" }}
        >
          <Box>
            <Typography
              fontSize="10px"
              color="white"
              style={{ userSelect: "none" }}
            >
              DELETE
            </Typography>
          </Box>
        </ButtonBase>
      </Box>
    </Box>
  );
};

const Keyboard = ({
  handleSubmit,
  letterValues,
  currentWord,
  setCurrentWord,
  testFunc,
  boardDisabled,
  prevWords,
  currentIndex,
  alertDialogOpen,
  goldenVowel,
}) => {
  const onClick = (key) => {
    if (key === "ENTER") {
      handleSubmit(currentWord);
    } else if (key === "DELETE") {
      const updatedGuess = [...currentWord];
      updatedGuess.pop();
      setCurrentWord(updatedGuess);
    } else if (currentWord.length < 5) {
      const updatedGuess = [...currentWord];
      updatedGuess.push(key);
      setCurrentWord(updatedGuess);
    }
  };

  return (
    <Box maxWidth="500px" marginBottom="10px" width="100vw">
      <RowOne
        boardDisabled={boardDisabled}
        letterValues={letterValues}
        onClick={onClick}
        testFunc={testFunc}
        rowFull={currentWord.length === 5}
        prevWords={prevWords}
        currentIndex={currentIndex}
        goldenVowel={goldenVowel}
      />
      <RowTwo
        boardDisabled={boardDisabled}
        letterValues={letterValues}
        onClick={onClick}
        rowFull={currentWord.length === 5}
        prevWords={prevWords}
        currentIndex={currentIndex}
        goldenVowel={goldenVowel}
      />
      <RowThree
        boardDisabled={boardDisabled}
        letterValues={letterValues}
        onClick={onClick}
        rowFull={currentWord.length === 5}
        rowEmpty={currentWord.length === 0}
        prevWords={prevWords}
        currentIndex={currentIndex}
        alertDialogOpen={alertDialogOpen}
      />
    </Box>
  );
};

export default Keyboard;
