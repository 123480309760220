import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

const Row = ({
  letters,
  submitted,
  letterValues,
  currentRow,
  index,
  clearRow,
  totalBox,
  currentTotal,
}) => {
  const row = [];

  for (let i = 0; i < 5; i++) {
    row.push(
      <Box
        display="flex"
        width="calc(100vw / 8.25)"
        maxWidth="65px"
        height={totalBox ? "30px" : "calc(100vw / 8.25)"}
        maxHeight="65px"
        justifyContent="center"
        alignItems="center"
        border={
          totalBox
            ? "2px solid transparent"
            : submitted
            ? "2px solid #4da357"
            : "2px solid #444444"
        }
        bgcolor={submitted ? "#4da357" : null}
        margin="3px"
      >
        <Typography
          fontSize="36px"
          fontWeight="700"
          color="white"
          style={{ userSelect: "none" }}
        >
          {letters && letters[i] ? letters[i].toUpperCase() : null}
        </Typography>
      </Box>
    );
  }

  const getValue = (row) => {
    let sum = 0;

    if (letters && letterValues) {
      letters.forEach((l) => {
        sum += letterValues.indexOf(l.toUpperCase());
      });
    }

    return sum;
  };

  row.push(
    <Box
      display="flex"
      width="calc(100vw / 4.5)"
      maxWidth="130px"
      height={totalBox ? "30px" : "calc(100vw / 8.25)"}
      maxHeight="65px"
      justifyContent="center"
      alignItems="center"
      border={submitted ? "2px solid #4da357" : "2px solid #444444"}
      margin="3px"
    >
      {totalBox ? (
        <Typography
          fontSize="20px"
          fontWeight="700"
          color="white"
          style={{ userSelect: "none" }}
        >
          {currentTotal}
        </Typography>
      ) : (
        <Typography
          fontSize="36px"
          fontWeight="700"
          color="white"
          style={{ userSelect: "none" }}
        >
          {(currentRow || getValue(row) > 0) && getValue(row)}
        </Typography>
      )}
    </Box>
  );

  return (
    <Box display="flex" onDoubleClick={() => clearRow(index)}>
      {row}
    </Box>
  );
};

export default Row;
