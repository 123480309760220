import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { Box } from "@mui/system";

const CopiedDialog = ({ copiedDialogOpen }) => {
  return (
    <Dialog
      open={copiedDialogOpen}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      PaperProps={{
        style: {
          backgroundColor: "rgb(30, 30, 30)",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        },
      }}
    >
      <DialogContent>
        <Box display="flex" justifyContent="flex-start">
          <DialogContentText
            id="alert-dialog-description"
            style={{ color: "#b0b0b0" }}
          >
            Copied to clipboard!
          </DialogContentText>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default CopiedDialog;
