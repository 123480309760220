import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyBmbFxeGZTbGhhL0yADlh-q_DGLGwKH-DE",
  authDomain: "wordgame416.firebaseapp.com",
  projectId: "wordgame416",
  storageBucket: "wordgame416.appspot.com",
  messagingSenderId: "992323360868",
  appId: "1:992323360868:web:0372feafac3e2da4ef6771",
  measurementId: "G-E5R4SP85E3"
};

export const firebase = initializeApp(firebaseConfig);

export const db = getFirestore(firebase);
export const analytics = getAnalytics(firebase);
